
.field-action-icon {
    font-size: 18px;
}

.color-green {
    color: #52c41a!important;
}


.color-red {
    color: #ff4d4f!important;
}

.color-blue {
    color: #1890ff!important;
}


.color-yellow {
    color:#faad14!important;
}


.message--sent {
    position: absolute;
    right: 3.15rem;
    bottom: -1.075rem;
    display: flex;
    align-items: center;
  }
  
  .message--sent svg {
    margin-left: 0.25rem;
  }
  
  .message--sent-date {
    position: absolute;
    left: 3.75rem;
    bottom: -1.075rem;
  }
  
  .message-text-contact {
    display: flex;
    align-items: flex-start;
    border-radius: 0.625rem;
    padding: 0.625rem;
    font-size: 1rem;
    margin: -0.625rem 0 0.25rem 0.75rem;
    background-color: #f0f2f5;
    word-wrap: anywhere;
  }
  
  .message:not(:first-child) {
    margin-top: 2rem;
  }
  .message:first-child {
    margin-top: 1rem;
  }
  
  .message-text-user {
    display: flex;
    align-items: flex-start;
    border-radius: 0.625rem;
    padding: 0.625rem;
    margin: -0.625rem 0.75rem 0.25rem 0;
    font-size: 1rem;
    background-color: #1890ff;
    color: #fff;
    word-wrap: anywhere;
  }
  
  .message-user-receiver {
    flex-direction: row-reverse;
  }
  
  .message {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    position: relative;
  }
  
  .message > .ant-avatar.ant-avatar-icon {
    flex-shrink: 0;
  }
  
 
.card-item {
  width: 150px;
  height: 150px;
  cursor: pointer;
}

.card-item > .ant-card-body > .ant-typography {
  display: inline flow-root list-item;
  margin-top: 40px;
  font-weight: 600;
}

.Bleue-card {
  border: 1px solid rgb(24, 144, 255) !important;
}
.Rouge-card {
  border: 1px solid #ff4d4f !important;
}
.Orange-card {
  border: 1px solid #faad14 !important;
}

.Bleue {
  color: rgb(24, 144, 255) !important;
}
.Violette {
  color: #800080 !important;
}
.Orange {
  color: #faad14 !important;
}

.menu-bleue {
  margin: 0 !important;
  color: darkslategray;
  background-color: #1890FFaa !important;
}
.menu-violette {
  margin: 0 !important;
  color: darkslategray;
  background-color: #800080aa !important;
}
.menu-orange {
  margin: 0 !important;
  color: darkslategray;
  background-color: #faad14aa !important;
}

.menu-bleue.ant-menu-item-active {
  color: white !important;
}
.menu-violette.ant-menu-item-active {
  color: white !important;
}
.menu-orange.ant-menu-item-active {
  color: white !important;
}

.menu-bleue.ant-menu-item-selected {
  color: white !important;
}
.menu-violette.ant-menu-item-selected {
  color: white !important;
}
.menu-orange.ant-menu-item-selected {
  color: white !important;
}

.ant-tree-indent:nth-child(3) {
  width: 24px;
}

.call-history-menu .ant-menu-item {
  padding-left: 14px!important;
  height: auto !important;
}

.call-history-menu .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: inherit!important;
}

.timeline-item-consultations .ant-tree-list-holder-inner {
  background: #f0f2f5;
}

.field-action-icon {
  font-size: 18px;
}


.patient-list-item  {
    cursor: pointer;
}

.symptoms-list-item:not(:first-of-type) {
    margin-top: 1rem;
}
.nested-checkbox-inputs-container {
    display: flex;
    flex-direction: column;
}

.nested-checkbox-inputs-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.nested-checkbox-inputs-row:first-of-type {
    margin-left: 2rem;
}

.nested-checkbox-inputs-row:not(:first-of-type) {
    margin-top: .5rem;
    margin-left: 5rem;
}
.patient-list-item  {
    cursor: pointer;
}
.header-top {
  display: flex;
  justify-content: flex-end;
  box-shadow: 0 2px 8px #e2e5e8;
  transition: all .3s cubic-bezier(.78,.14,.15,.86);
}

.header-actions {
  display: flex;
  align-items: center;
  padding-right: 60px;
  flex: 1 1;
  justify-content: flex-end;
}

#logo {
  height: 50px;
  padding-left: 40px;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.85);
  font-size: 18px;
  line-height: 64px;
  white-space: nowrap;
  text-decoration: none;
  padding-top: 10px;
}

#logo img {
  position: relative;
  height: 40px;
}

.icon-logo {
  display: none;
}

img.icon-logo {
  height: 32px;
  position: relative;
  bottom: 13px;
  left: 9px;
}

:root {
  --main-bg-color: #f0f2f5;
}

.app-content {
  min-height: 86vh;
}

.header-title {
  color: #001529 !important;
  text-align: center;
  line-height: inherit !important;
}

.section-content {
  margin: 50px;
}

.layout-bg {
  background: #ffff;
}

.login-card{
  width: 350px;
  margin: 10% auto 0;
  opacity: 0.9;
  z-index: 99999;
}

.success-button {
  color: #5fc114;
  background-color: inherit;
  border: 1px solid #5fc114;
}

.success-button:hover {
  color: #83c84e;
  background-color: inherit;
  border: 1px solid #83c84e;
}

.icon-button {
  margin-right: 5px;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex {
  display: flex;
}

.flex-wrap {
  flex-wrap: wrap;
  
}

.m-auto {
  margin: auto;
}

.center-button {
  display: block;
  margin: auto;
}

.center-text {
  text-align: center;
}

.m-top-medium {
  margin-top: 14px;
}

.m-top-small {
  margin-top: 5px;
}

.m-bottom-small {
  margin-bottom: 5px;
}

.m-bottom-medium {
  margin-bottom: 14px;
}

.m-left-small {
  margin-left: 5px;
}

.m-right-small {
  margin-right: 5px;
}

.m-left-medium {
  margin-left: 14px;
}

.m-right-medium {
  margin-right: 14px;
}

.p-medium {
  padding: 14px;
}

.p-small {
  padding: 5px;
}

.p-top-meidum {
  padding-top: 14px;
}

.m-bottom-large {
  margin-bottom: 24px;
}

#unpadded-card .ant-card-body {
  padding: 0;
}

/* OVERRIDES */

.ant-layout-sider-trigger {
  background: #fff;
  color: #002140;
}

.ant-avatar {
  background: #1890ff;
}

.side-bar-menu .ant-menu-item-icon {
  font-size: 16px;
}


:root {
  --animate-duration: 2s !important
}

.fixed-size  {
  display:block;
  max-width: 180px;
}
  
