.card-item {
  width: 150px;
  height: 150px;
  cursor: pointer;
}

.card-item > .ant-card-body > .ant-typography {
  display: inline flow-root list-item;
  margin-top: 40px;
  font-weight: 600;
}

.Bleue-card {
  border: 1px solid rgb(24, 144, 255) !important;
}
.Rouge-card {
  border: 1px solid #ff4d4f !important;
}
.Orange-card {
  border: 1px solid #faad14 !important;
}

.Bleue {
  color: rgb(24, 144, 255) !important;
}
.Violette {
  color: #800080 !important;
}
.Orange {
  color: #faad14 !important;
}

.menu-bleue {
  margin: 0 !important;
  color: darkslategray;
  background-color: #1890FFaa !important;
}
.menu-violette {
  margin: 0 !important;
  color: darkslategray;
  background-color: #800080aa !important;
}
.menu-orange {
  margin: 0 !important;
  color: darkslategray;
  background-color: #faad14aa !important;
}

.menu-bleue.ant-menu-item-active {
  color: white !important;
}
.menu-violette.ant-menu-item-active {
  color: white !important;
}
.menu-orange.ant-menu-item-active {
  color: white !important;
}

.menu-bleue.ant-menu-item-selected {
  color: white !important;
}
.menu-violette.ant-menu-item-selected {
  color: white !important;
}
.menu-orange.ant-menu-item-selected {
  color: white !important;
}

.ant-tree-indent:nth-child(3) {
  width: 24px;
}

.call-history-menu .ant-menu-item {
  padding-left: 14px!important;
  height: auto !important;
}

.call-history-menu .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: inherit!important;
}

.timeline-item-consultations .ant-tree-list-holder-inner {
  background: #f0f2f5;
}

.field-action-icon {
  font-size: 18px;
}

