.nested-checkbox-inputs-container {
    display: flex;
    flex-direction: column;
}

.nested-checkbox-inputs-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.nested-checkbox-inputs-row:first-of-type {
    margin-left: 2rem;
}

.nested-checkbox-inputs-row:not(:first-of-type) {
    margin-top: .5rem;
    margin-left: 5rem;
}