@import "~antd/dist/antd.css";

:root {
  --main-bg-color: #f0f2f5;
}

.app-content {
  min-height: 86vh;
}

.header-title {
  color: #001529 !important;
  text-align: center;
  line-height: inherit !important;
}

.section-content {
  margin: 50px;
}

.layout-bg {
  background: #ffff;
}

.login-card{
  width: 350px;
  margin: 10% auto 0;
  opacity: 0.9;
  z-index: 99999;
}

.success-button {
  color: #5fc114;
  background-color: inherit;
  border: 1px solid #5fc114;
}

.success-button:hover {
  color: #83c84e;
  background-color: inherit;
  border: 1px solid #83c84e;
}

.icon-button {
  margin-right: 5px;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex {
  display: flex;
}

.flex-wrap {
  flex-wrap: wrap;
  
}

.m-auto {
  margin: auto;
}

.center-button {
  display: block;
  margin: auto;
}

.center-text {
  text-align: center;
}

.m-top-medium {
  margin-top: 14px;
}

.m-top-small {
  margin-top: 5px;
}

.m-bottom-small {
  margin-bottom: 5px;
}

.m-bottom-medium {
  margin-bottom: 14px;
}

.m-left-small {
  margin-left: 5px;
}

.m-right-small {
  margin-right: 5px;
}

.m-left-medium {
  margin-left: 14px;
}

.m-right-medium {
  margin-right: 14px;
}

.p-medium {
  padding: 14px;
}

.p-small {
  padding: 5px;
}

.p-top-meidum {
  padding-top: 14px;
}

.m-bottom-large {
  margin-bottom: 24px;
}

#unpadded-card .ant-card-body {
  padding: 0;
}

/* OVERRIDES */

.ant-layout-sider-trigger {
  background: #fff;
  color: #002140;
}

.ant-avatar {
  background: #1890ff;
}

.side-bar-menu .ant-menu-item-icon {
  font-size: 16px;
}


:root {
  --animate-duration: 2s !important
}

.fixed-size  {
  display:block;
  max-width: 180px;
}
  